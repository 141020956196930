import React from 'react'
import { useTranslation } from 'react-i18next'

import { AllDiscountsData } from '@src/hooks/sharedQueries/useAllDiscounts/useAllDiscounts'
import { useFormatCurrency } from '@src/hooks/useFormatCurrency'

import {
  DiscountText,
  StyledCouponSVG,
  TermsText,
  SingleDiscountContainer,
  CouponAndTextContainer,
} from './SingleDiscounts.styles'

export const SingleDiscount: React.FC<{
  allDiscounts: AllDiscountsData[number]
}> = ({ allDiscounts }) => {
  const { t } = useTranslation('discounts')
  const { discountPercentage, discountAmount, minimumSubtotalGross, name } =
    allDiscounts

  const formatCurrency = useFormatCurrency()

  const discountPhrase = discountPercentage
    ? t('percent_off', { discountPercentage })
    : t('amount_off', {
        discountAmount: formatCurrency(discountAmount || 0),
      })

  const discountDescription = minimumSubtotalGross
    ? t('orders_over', {
        minimumSubtotal: formatCurrency(minimumSubtotalGross),
        discountPhrase,
      })
    : t('on_any_order', { discountPhrase })

  return (
    <SingleDiscountContainer>
      <CouponAndTextContainer>
        <StyledCouponSVG id="discount" />
        <DiscountText>{name}</DiscountText>
      </CouponAndTextContainer>
      <TermsText>{discountDescription}</TermsText>
    </SingleDiscountContainer>
  )
}
