import styled from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const AllergenContainer = styled.span`
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex-wrap: wrap;
  justify-content: center;
  font-weight: 700;

  @media (min-width: ${breakpoints.tablet}px) {
    padding-top: 4px;
  }
`

export const DotContainer = styled.p.attrs(() => ({ role: 'separator' }))<{
  isMenuitem?: boolean
}>`
  display: flex;
  padding: 0px 4px 3px 4px;
  line-height: 24px;
  margin: auto;
  font-weight: ${props => (props.isMenuitem ? '400' : '700')};

  color: #2e3333;

  @media (min-width: ${breakpoints.desktop}px) {
    padding: 0px 4px 0px 4px;
  }
`

export const AllergenLabel = styled.p<{ isMenuitem?: boolean }>`
  margin: 0;
  font-size: ${props => (props.isMenuitem ? '14' : '12')}px;
  line-height: ${props => (props.isMenuitem ? '48' : '24')}px;
  font-weight: ${props => (props.isMenuitem ? '700' : '400')};

  @media (min-width: ${breakpoints.desktop}px) {
    line-height: 24px;
    padding-top: 1px;
  }
`

export const AllergenIcon = styled.div<{ large?: boolean }>`
  background-color: #2e3333;
  color: #fff;
  height: ${props => (props.large ? '20' : '14')}px;
  width: ${props => (props.large ? '20' : '14')}px;
  font-size: ${props => (props.large ? '10' : '8')}px;
  border-radius: 4px;

  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1px;
  align-self: flex-start;

  @media (min-width: ${breakpoints.tablet}px) {
    height: 20px;
    min-width: 20px;
    font-size: 10px;
  }
`

export const AgeRestrictedIcon = styled.div<{ large?: boolean }>`
  height: ${props => (props.large ? '20' : '14')}px;
  width: ${props => (props.large ? '20' : '14')}px;
  font-size: ${props => (props.large ? '10' : '8')}px;
  font-weight: 700;
  background-color: #2e3333;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  margin-left: 2px;
  margin-top: 1px;

  @media (min-width: ${breakpoints.tablet}px) {
    height: 16px;
    width: 16px;
    padding-right: 1px;
    font-size: 10px;
    border: 2px solid #2e3333;
  }
`

export const AllergenIconContainer = styled.div`
  display: flex;
`
