import styled from 'styled-components'

import { CouponSVG } from '@src/components/SVGS/CouponSVG'
import { breakpoints } from '@src/constants/breakpoints'

export const Container = styled.div<{
  compactStyle: boolean
}>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 150px;
  align-items: flex-end;
  margin: 0;
  transition: ease-in-out 0.3s;
  padding-bottom: 8px;

  @media (min-width: ${breakpoints.tablet}px) {
    flex-direction: row;
  }
`

export const DiscountContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 12px;
  overflow: hidden;
  flex-wrap: wrap;
  @media (min-width: ${breakpoints.tablet}px) {
    padding-left: 64px;
    color: ${({ theme }) => theme.colors.darkGrey};
  }
`

export const SingleDiscountContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 8px 8px 8px 12px;
  border-radius: 8px;
  min-width: 240px;
  z-index: -1;
  background-color: white;
  margin-right: 16px;
  height: 42px;
  border: 2px solid ${({ theme }) => theme.colors.discount};

  @media (min-width: ${breakpoints.tablet}px) {
    padding: 18px 24px 18px 18px;
    min-width: 270px;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    min-width: 240px;
  }
`

export const CouponAndTextContainer = styled.div`
  display: flex;
  align-items: center;
`

export const StyledCouponSVG = styled(CouponSVG)`
  width: 18px;
  height: 18px;
  padding-top: 2px;
  color: ${({ theme }) => theme.colors.discount};
  @media (min-width: ${breakpoints.desktop}px) {
    width: 22px;
    height: 22px;
  }
`

export const DiscountText = styled.span`
  font-size: 14px;
  font-weight: 700;
  margin-left: 12px;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.discount};

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 16px;
  }
`

export const TermsText = styled.span`
  font-size: 12px;
  font-weight: 400;
  margin-left: 30px;
  color: ${({ theme }) => theme.colors.darkText};

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 14px;
    margin-left: 34px;
  }
`
