import styled from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const ItemsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid ${({ theme }) => theme.colors.grey};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};

  @media (min-width: ${breakpoints.tablet}px) {
    border-top: none;
    border-bottom: none;
    margin: 0 8px 8px 8px;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    margin: 0 0 16px 0;
  }
`

export const ItemContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  padding: 0px 0 0 16px;
 
  &:hover {
   box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    width: 48%;
    margin: 0 0 8px 8px;
      border-radius: 8px;
  }

  @media (min-width: ${breakpoints.largeTablet}px) {
    width: 31%;
    margin: 0 0 10px 10px;
      border-radius: 8px;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    width: calc((100% / 3)) - 4px);
    margin: 0 0 16px 16px;
      border-radius: 8px;
  }
`

export const HeaderContainer = styled.div<{ hasDescription: boolean }>`
  padding: 18px 16px 8px 16px;
  border-top: rgba(0, 0, 0, 0.04) solid 2px;

  @media (min-width: ${breakpoints.tablet}px) {
    border-top-width: 8px;
    padding: 24px 32px 8px 32px;
  }
  @media (min-width: ${breakpoints.desktop}px) {
    border-top-width: 8px;
    padding: 32px 32px 16px 32px;
  }
`

export const SubMenuHeader = styled.div`
  padding: 12px 8px 8px 16px;
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.brand};
  border-top: none;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 16px;
    padding: 16px 32px;
    border-top: 2px dotted ${({ theme }) => theme.colors.grey};
  }
  @media (min-width: ${breakpoints.desktop}px) {
    font-size: 18px;
    padding: 16px 32px;
    border-top: 2px dotted ${({ theme }) => theme.colors.grey};
  }
`

export const Header = styled.h1<{ hasDescription: boolean }>`
  font-size: 16px;
  font-weight: 700;
  margin: 0px;
  color: ${({ theme }) => theme.colors.mainText};

  @media (min-width: ${breakpoints.tablet}px) {
    margin-bottom: ${props => (props.hasDescription ? '4px' : '0px')};
    font-size: 20px;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    margin-bottom: ${props => (props.hasDescription ? '4px' : '0px')};
    font-size: 24px;
  }
`

export const Description = styled.p`
  font-weight: 400;
  font-size: 13px;
  margin: 0px 0px 0px 0px;
  color: ${({ theme }) => theme.colors.mainText};

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 14px;
    margin: 0px 0px 8px 0px;
  }
`

export const ScrollToContainer = styled.div`
  position: absolute;
  height: 40px;
  width: 1px;
  background-color: transparent;
  // Scroll behaviour puts the menu behind the header, so set the container to scroll to 260px margin down
  margin-top: -220px;

  @media (min-width: ${breakpoints.tablet}px) {
    margin-top: -280px;
  }

  @media (min-width: ${breakpoints.largeTablet}px) {
    margin-top: -200px;
  }
`

export const ObservableContainer = styled.div`
  width: 10px;
  background-color: transparent;
  height: 16px;
  position: absolute;
  z-index: 100;
`
