import { Carousel } from 'react-responsive-carousel'
import styled, { css } from 'styled-components'

import { Button } from '@src/components/Button'
import { breakpoints } from '@src/constants/breakpoints'
import { removeScrollbar } from '@src/pages/global.styles'

export const ErrorContainer = styled.div<{ extraDanger: boolean }>`
  position: fixed;
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  background-color: ${({ extraDanger }) =>
    extraDanger ? '#cf3d39' : '#fbe8ec'};
  color: ${({ extraDanger }) => (extraDanger ? '#fbe8ec' : '#cf3d39')};
  top: 0px;
  left: 0px;
  z-index: 1;
  border-radius: 8px 8px 0 0;
  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.2));

  ${({ extraDanger }) =>
    extraDanger &&
    css`
      animation: shake-effect 0.3s;
    `}

  @keyframes shake-effect {
    0% {
      transform: translateX(0);
    }
    10% {
      transform: translateX(4px);
    }
    30% {
      transform: translateX(-4px);
    }
    50% {
      transform: translateX(4px);
    }
    70% {
      transform: translateX(-4px);
    }
    90% {
      transform: translateX(4px);
    }
    100% {
      transform: translateX(0px);
    }
  }
`

export const ErrorText = styled.p`
  padding: 24px;
  font-weight: 700;
`

export const FormContainer = styled.div`
  margin: 0;
`

export const UpperContainer = styled.div`
  overflow-x: scroll;
  height: calc(100% - 64px);
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  margin: 0 8px;

  @media (min-width: ${breakpoints.tablet}px) {
    margin: 0 24px;
  }
`

export const RefContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const AllergenContainer = styled.div<{ hasAllergens: boolean }>`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  margin-left: 8px;

  @media (min-width: ${breakpoints.tablet}px) {
    margin-left: 24px;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  padding: 0 8px 4px 8px;
  justify-content: space-between;

  @media (min-width: ${breakpoints.tablet}px) {
    padding: 0px 24px;
    padding-top: 12px;
  }
`

export const Header = styled.h2`
  color: ${({ theme }) => theme.colors.brand};
  font-weight: 700;
  font-size: 16px;
  margin: 0;
  padding: 0;
  margin-right: 8px;

  @media (min-width: ${breakpoints.tablet}px) {
    padding: 0;
    padding-right: 8px;
    font-size: 20px;
  }
`

export const SpiceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 8px;
`
export const InnerHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const SmallFont = styled.p`
  font-weight: 400;
  font-size: 12px;
  margin: 0px 8px;
  white-space: pre-line;
  color: ${({ theme }) => theme.colors.darkText};

  @media (min-width: ${breakpoints.tablet}px) {
    margin: 8px 24px 0px 24px;
    font-size: 14px;
  }
`

export const Legend = styled.h3`
  display: flex;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  margin: 24px 0 8px 0;
`

export const AddToBasketFooterContainer = styled.div`
  left: 0;
  width: 100%;
  background-color: #fff;
  bottom: 0;
  position: absolute;
  opacity: 1;
  z-index: 12;
  border-top: 1px solid rgba(149, 157, 165, 0.2);
  box-shadow: rgba(149, 157, 165, 0.4) 0px 8px 24px;
`

export const AddToBasketFooter = styled.div`
  padding: 8px;
  display: flex;
  justify-content: flex-end;
  padding: 16px 8px max(15px, env(safe-area-inset-bottom, 0px) + 16px);

  @media (min-width: ${breakpoints.tablet}px) {
    justify-content: center;
  }
`

export const AddToBasketButton = styled(Button)`
  font-size: 16px;
  height: 48px;
  width: 100%;
  margin: 0px 8px 0;

  @media (min-width: ${breakpoints.tablet}px) {
    height: 60px;
    align-self: center;
  }
`

export const AddToBasketButtonLabel = styled.span`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 24px;
  font-size: 14px;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 16px;
  }
`

export const SingleMenuItemModalContainer = styled.div<{
  showModalFooter: boolean
}>`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin: 0 8px;
  margin-bottom: ${({ showModalFooter }) => (showModalFooter ? '128px' : '0')};

  ${removeScrollbar}
`

export const ImageContainer = styled.div<{ imageUrl?: string }>`
  ${props =>
    props.imageUrl
      ? css`
          background-image: url(${props.imageUrl});
        `
      : css`
          background-color: #f6f6f9;
        `};

  background-repeat: no-repeat;
  height: 30vh;
  width: auto;
  border-radius: 8px;
  margin: 0 0 16px 0;
  background-size: cover;
  background-position: center;
  background-color: #f6f6f9;
`

export const StyledCarousel = styled(Carousel)`
  .carousel .thumb {
    width: 80px;
    height: auto;
    border-radius: 6px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .carousel .thumb img {
    width: 80px;
    height: 50px;
  }

  .carousel .thumb.selected {
    border: 2px solid;
  }

  .carousel .thumb:hover {
    border: 2px solid;
  }

  .carousel .thumbs-wrapper {
    margin: 10px 0 0 0;
    height: 80px;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .carousel .thumbs {
    padding: 0;
    display: flex;
    flex-direction: row;
  }
  .carousel .slide img {
    background-repeat: no-repeat;
    height: 30vh;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-color: #f6f6f9;
  }

  .carousel .slider-wrapper {
    height: 30vh;
    width: 100%;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .carousel .control-prev.control-arrow {
    border-radius: 8px 0px 0px 8px;
  }

  .carousel .control-next.control-arrow {
    border-radius: 0px 8px 8px 0px;
  }

  .carousel .slide .legend {
    height: auto;
    bottom: 20px;
    display: inline-block;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    align-self: center;
    max-width: 100vw;
    text-overflow: ellipsis;
    opacity: 1;
    background-color: white;
    color: black;
  }

  .carousel .control-dots {
    margin: 8px 0;
  }
`
